/*!
 * Fluidable Grid System 1.3
 *
 * Creator: Andri Sigurðsson
 * Site: http://fluidable.com
 * Date: 31.05.2016
 */

// This grid has been modified a bit to make it work for uxcup.bekk.no.

// Grid

@gutterWidth: 5vw;
@leftMargin: 1.4rem;

// Break-points

@mobileMax: 30rem; // 480px
@tablet: 48rem; // 768px
@smallDesktop: 75rem; // 1200px
@largeDesktop: 110rem; // 1760px
@extraLargeDesktop: 138rem; // 2208px
@maxWidth: 160rem; // 2560px

// Positioning

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
