@import "./styles/utils/fluidable";
@import "./styles/utils/vars";

.modal {
  width: 80vw;
  height: 75vh;
  background: @color-white;
  color: @color-white;
  box-shadow: 0 5px 20px 0 @color-black;

  @media (max-width: @tablet) {
    width: 90vw;
    height: 90vh;
  }

  &__laptop-brand-button {
    font-size: 1.125rem;
    margin-right: 1rem;
  }

  &__navigation-button-container {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: 1.5em;
    right: 1.5em;
  }

  &__navigation-button {
    display: flex;
    align-items: center;
    margin: 0 8px;

    p {
      height: 0;
    }
  }

  &__description {
    margin: 1em 0;
    padding-left: 40px;
    text-align: left;
    height: 100%;

    h1 {
      font-size: 24px;
    }

    @media (max-width: @tablet) {
      margin: 2em 0;
      padding-left: 0;

      p,
      li {
        line-height: normal;
      }
      ol {
        padding-left: 1.5em;
        padding-top: 0.5em;
      }
    }
  }
}

.bildeboks1,
.bildeboks2,
.bildeboks3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 80vh;
  //min-width: 50%;
  @media (min-width: @tablet) {
    width: 50%;
    max-height: 70vh;
  }
}

.bildeboks1 {
  padding: 1em;
  max-width: 100%;
  max-height: 100%;
  @media (min-width: @tablet) {
    width: fit-content;
    max-height: 55vh;
    min-width: none !important;
    min-height: none !important;
  }
}

.bildeboks2 > img {
  padding: 0.5em;
  max-height: 35vh;
  @media (min-width: @tablet) {
    max-height: fit-content;
    max-width: 15vw;
  }
}
.bildeboks3 > img {
  max-width: 60%;
  padding: 0.5em;
  align-self: center;
  max-height: 50%;
}

.bildeboks3 > img:last-child {
  max-width: 100%;
}

.bildeboks3 > img:not(:last-child) {
  max-height: 30vh;
  @media (max-width: @tablet) {
    max-height: 40vh;
  }
}
