@hoverDuration: 0.3s;

.header-logo-animation(@animateColor, @initialColor) {
  fill: @initialColor;

  path {
    fill: @animateColor;
    transition-duration: @hoverDuration;
    animation-name: logofade;
    animation-timing-function: @bekk-bezier-curve;
    animation-duration: @hoverDuration / 2;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .b-upper {
    animation-delay: @hoverDuration + 0.3s;
  }

  .b-lower {
    animation-delay: @hoverDuration + 0.1s;
  }

  .e-upper {
    animation-delay: @hoverDuration;
  }

  .e-lower {
    animation-delay: @hoverDuration + 0.2s;
  }

  .k1-upper {
    animation-delay: @hoverDuration + 0.3s;
  }

  .k1-lower {
    animation-delay: @hoverDuration + 0.2s;
  }

  .k2-upper {
    animation-delay: @hoverDuration + 0.1s;
  }

  .k2-lower {
    animation-delay: @hoverDuration;
  }
}

@keyframes logofade {
  to {
    fill: inherit;
  }
}
