.login__input {
  background: transparent;
  border: none;
  border-bottom: 1px solid @color-white;
  font-size: 20px;
  padding: 10px;
  margin-bottom: 20px;
  color: @color-white;
  border-radius: 0;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: auto;
  margin-top: 10vh;

  @media screen and (min-width: 400px) {
    margin-top: 20vh;
  }
}

button[type='submit'] {
  border: 1px solid @color-white;
  padding: 10px 20px;
  background: transparent;
  color: inherit;

  background-color: transparent;
  background-image: linear-gradient(#ff8034, #ff8034);
  background-repeat: no-repeat;
  background-position: 0 50px;
  transition: background-position 200ms;

  @media screen and (min-width: 400px) {
    align-self: flex-end;
  }

  &:hover {
    background-position: 0 0;
  }
}

.login__error {
  color: #ffb88d;
  padding: 30px 20px;

  @media screen and (min-width: 400px) {
    text-align: center;
  }
}
