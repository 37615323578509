@import './animations/logo';

@mainMenuAnimationDuration: 500ms; //this has to be the same as MAIN_MENU_ANIMATION_DURATION_IN_MS in constants.js

header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding-top: 2em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 3;

  @media only screen and (max-width: 480px) {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

header a {
  transition: opacity 1s ease-in-out;
}

.header-logo-container {
  cursor: pointer;
}

.header__logo {
  height: 18px;
  vertical-align: middle;
}

.header-logo-container .header-logo-container--black {
  position: relative;

  path {
    transition-property: fill;
    transition-timing-function: @bekk-bezier-curve;
    transition-duration: 0s;
  }

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    z-index: -1;
  }
}

.header-logo-container:hover {
  .header-logo-animation(@color-dark-gray, @color-white);
}

nav {
  background: @color-black;
  height: 100vh;
  padding: 0 1.4rem;
  position: fixed;
  width: 100%;
  transition: 500ms cubic-bezier(0.77, 0.3, 0.39, 0.9);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: @tablet) {
    height: 36vh;
    padding: 0 5vw 0;
    align-items: flex-start;
    justify-content: flex-end;
  }

  &.hidden {
    transition-duration: 300ms;
    transform: translateY(-100%);
  }
}

.nav__links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 2rem;

  font-family: regular;
  font-size: 31 / (16 * @mobileFontSize); //36px

  @media (min-width: @tablet) {
    flex-direction: row;
    font-size: 35 / (16 * @tabletFontSize); //40px
  }

  @media (min-width: @smallDesktop) {
    font-size: 40 / (16 * @desktopFontSize); //45px
  }

  @media (min-width: @largeDesktop) {
    font-size: 50 / (16 * @bigDesktopFontSize); //55px
  }

  @media (min-width: @extraLargeDesktop) {
    font-size: 60 / (16 * @largeScreenFontSize); //65px
  }
}

.nav__links__link {
  color: #b3b3b3;
  transition: 300ms;
  margin-right: 0.9em;

  &:hover {
    color: @color-white;
  }
}

.nav__links--small {
  color: #b3b3b3;
  margin-bottom: 1.5em;
}

.nav__links--small__link {
  margin-right: 1.2em;

  border-color: @color-gray;
  transition: color 0.3s ease-out;
  font-size: 20px;

  &:hover {
    color: @color-white;
    border-color: @color-gray;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background: #3b3b3b;
  opacity: 0;
  z-index: -1;
  transition: opacity 500ms cubic-bezier(0.77, 0.3, 0.39, 0.9);

  &.visible {
    opacity: 0.7;
    transform: translate3d(0, 0, 0);
    // transition: opacity 500ms cubic-bezier(0.77, 0.3, 0.39, 0.9);
    z-index: 1;
  }
}

// animation: mainMenuSlideIn 500ms cubic-bezier(0.77, 0.3, 0.39, 0.9) forwards;
