@import './animations/pride';

@hoverTransitionDistance: 3px;

.menu-button {
  background: none;
  border: none;
  height: 45px;
  width: 45px;
  margin: 0 1.4rem;
  padding: 0;

  @media screen and (min-width: @tablet) {
    margin-right: 1.6rem;
  }
}

.menu-button__icon {
  // clearly define starting state so that this doesn't look weird on Safari when animation doesn't completely finish.
  .lineContainer {
    transform: translateX(0);
  }

  .topLine {
    transform: rotateZ(0) translateX(0) translateY(0);
  }
  .middleLine {
    opacity: 1;
  }
  .bottomLine {
    transform: rotateZ(0) translateX(0) translateY(0);
  }
}

.menu-button__icon {
  path {
    transition: transform 0.3s ease-in-out;
  }

  &:hover:not(.expanded):not(.hiding) {
    .topLine {
      transform: translateY(-@hoverTransitionDistance);
    }

    .bottomLine {
      transform: translateY(@hoverTransitionDistance);
    }
  }
}

.menu-button__icon.pride {
  .topLine {
    animation: pride 0.8s 200ms forwards linear infinite;
    // stroke-width: 5;
  }

  .middleLine {
    animation: pride 0.8s 100ms forwards linear infinite;
    // stroke-width: 5;
  }

  .bottomLine {
    animation: pride 0.8s forwards linear infinite;
    // stroke-width: 5;
  }
}

@rotation: 45deg;
@translateX: 2.5px;
@translateY: 10px;

.menu-button__icon.expanded {
  path {
    transition: transform @mainMenuAnimationDuration ease-in-out,
      opacity 100ms ease-in-out;
    transform-origin: center center;
  }

  .lineContainer {
    transition: transform @mainMenuAnimationDuration ease-in-out;
    transform: translateX(-4px);
  }
  .topLine {
    transform: rotateZ(@rotation) translateX(@translateX)
      translateY(@translateY);
  }
  .middleLine {
    opacity: 0;
  }
  .bottomLine {
    transform: rotateZ(-@rotation) translateX(@translateX)
      translateY(-@translateY);
  }
}

.menu-button__icon.hiding {
  path {
    transition: opacity @mainMenuAnimationDuration ease-in-out;
    transform-origin: center center;
  }
  .topLine {
    animation: hideMainMenuIconTopLine @mainMenuAnimationDuration ease-in-out
      forwards;
  }
  .middleLine {
    opacity: 1;
  }
  .bottomLine {
    animation: hideMainMenuIconBottomLine @mainMenuAnimationDuration ease-in-out
      forwards;
  }
}

@keyframes hideMainMenuIconTopLine {
  from {
    transform: rotateZ(-@rotation) translateX(@translateX - 3.5px)
      translateY(@translateY + 2px);
  }
}

@keyframes hideMainMenuIconBottomLine {
  from {
    transform: rotateZ(@rotation) translateX(@translateX - 3.5px)
      translateY(-@translateY - 2px);
  }
}

@supports (-ms-ime-align: auto) {
  // IE Edge specific styling
  .crappyVersion;
}

.crappyVersion {
  .menu-button__icon.expanded {
    .lineContainer {
      animation: none !important;
      transform: none !important;
    }
    path {
      transform: none !important;
      animation: none !important;
      transition: opacity @mainMenuAnimationDuration ease-in-out;
    }
    path:nth-child(odd) {
      opacity: 0;
    }
    path:nth-child(even) {
      opacity: 1;
    }
  }
}
