body {
  background: @color-black;
  color: @color-white;
  font-family: @font-regular;
  font-size: @mobileFontSize;
  line-height: @default-line-height;
  position: relative;
  scroll-behavior: auto;
  z-index: @z-index-default;
  @media (min-width: @smallDesktop) {
    line-height: @default-desktop-line-height;
  }
}

body.whitePageView {
  background: @color-white;
  color: @color-dark-gray;
}

h1{
  line-height: @default-title-line-height;
  font-family: @font-regular;
  font-weight: 300;
}

h3 {
  font-weight: bold;
  line-height: @default-desktop-line-height;
  font-family: @font-medium;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  border-bottom: 1px solid @color-black;
  line-height: 0.8;
  display: inline-block;
  transition: border-color 200ms ease-in-out;
}

a:hover {
  border-color: @color-medium-gray;
}

a.noUnderline {
  background: none;
  text-shadow: none;
  border-bottom: none;
  display: inherit;
  line-height: inherit;
}

a.noUnderline:hover {
  opacity: 1;
}

@media (min-width: @tablet) {
  body {
    font-size: @tabletFontSize;
  }
}

@media (min-width: @smallDesktop) {
  body {
    font-size: @desktopFontSize;
  }
}

@media (min-width: @largeDesktop) {
  body {
    font-size: @bigDesktopFontSize;
  }
}

@media (min-width: @extraLargeDesktop) {
  body {
    font-size: @largeScreenFontSize;
  }
}

// header,
// main,
// .mainMenuIconContainer {
//   margin: 0 1.4rem;
// }

// #mainMenu {
//   padding: 0 1.4rem;
// }

@media (min-width: @tablet) {
  main {
    margin: 0 @gutterWidth / 2;
  }

  // #mainMenu {
  //   padding: 0 @gutterWidth;
  // }

  // header {
  //   margin: 0;
  // }

  // .mainMenuIconContainer {
  //   margin-right: 1.6em;
  // }

  // @media (min-width: @smallDesktop) {
  //   header,
  //   .mainMenuIconContainer {
  //     margin: 0 @gutterWidth / 2;
  //   }

  //   #mainMenu {
  //     padding: 0 @gutterWidth / 2;
  //   }
  // }
}

@media (min-width: 0) and (max-width: (@tablet - 1rem)) {
  .util-tabletAndBigger {
    display: none !important;
  }
}

@media (min-width: 0) and (max-width: @smallDesktop) {
  .util-desktopOnly {
    display: none !important;
  }
}

@media (min-width: @smallDesktop) {
  .util-mobileAndTablet {
    display: none !important;
  }
}

:focus {
  outline: solid @color-white 1px;
  transition: outline 300ms ease-in-out;
}

::selection {
  background-color: @color-light-gray;
}

.paragraphs p:not(:last-of-type) {
  margin-bottom: 1em;
}
