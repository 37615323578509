@keyframes pride {
  0% {
    stroke: #e70000;
  }
  19% {
    stroke: #e70000;
    stroke-width: 5;
  }
  20% {
    stroke: #ff8c00;
  }
  39% {
    stroke: #ff8c00;
  }
  40% {
    stroke: #ffef00;
  }
  59% {
    stroke: #ffef00;
  }
  60% {
    stroke: #00811f;
  }
  79% {
    stroke: #00811f;
  }
  80% {
    stroke: #0044ff;
  }
  89% {
    stroke: #0044ff;
  }
  90% {
    stroke: #760089;
  }
  100% {
    stroke: #760089;
  }
}
