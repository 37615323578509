@import "utils/normalize";
@import "utils/fluidable";
@import "utils/vars";
@import "utils/accessibility";

@import "login";
@import "index";
@import "header";
@import "main-menu";

@font-face {
  font-family: "Regular-font";
  src: local("GT-America-Standard-Regular"),
    url("../assets/fonts/GT-America-Standard-Regular.woff") format("woff");
}

@font-face {
  font-family: "Regular-Italic-font";
  src: local("GT-America-Standard-Regular-Italic"),
    url("../assets/fonts/GT-America-Standard-Regular-Italic.woff")
      format("woff");
}

@font-face {
  font-family: "Medium-font";
  src: local("GT-America-Standard-Medium"),
    url("../assets/fonts/GT-America-Standard-Medium.woff") format("woff");
}

@font-face {
  font-family: "Medium-Italic";
  src: local("GT-America-Standard-Medium-Italic"),
    url("../assets/fonts/GT-America-Standard-Medium-Italic.woff") format("woff");
}

@font-face {
  font-family: "Expanded-Regular";
  src: local("GT-America-Expanded-Regular"),
    url("../assets/fonts/GT-America-Expanded-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Expanded-Medium";
  src: local("GT-America-Expanded-Medium"),
    url("../assets/fonts/GT-America-Expanded-Medium.ttf") format("truetype");
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
}

main {
  margin: 0;
}

h2 {
  margin-bottom: 0.7rem;
  line-height: @default-title-line-height;
  font-weight: 300;
  font-size: x-large;
}

p {
  margin: 0;
  font-size: large;
}

p.text {
  margin-bottom: 1.5em;
}

ol {
  line-height: 200%;
  font-size: large;
}

ul {
  line-height: 150%;
  font-size: large;
  padding: 0;
  li {
    list-style-position: inside;
    margin-bottom: 1rem;
  }
}

li::marker {
  margin: 0;
}

button {
  font-family: @font-expanded-regular;
  cursor: pointer;
  background: transparent;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: solid 1px;
  padding: 0.4em 0.65em 0.3em 0.65em;
  width: fit-content;
  border-color: @color-black;
  color: @color-black;
  :last-child {
    padding-left: 12px;
  }
  :first-child {
    padding-left: 0px;
  }
  .light {
    display: none;
  }
  .dark {
    padding-bottom: 2px;
    display: block;
    align-self: center;
    height: 17px;
  }
}

.button-light {
  font-family: @font-expanded-regular;
  cursor: pointer;
  border-color: @color-white;
  color: @color-white;
  :not(:first-child) {
    padding-left: 12px;
  }
  :first-child {
    padding-left: 0px;
  }
  .light {
    padding-bottom: 2px;
    display: block;
    align-self: center;
    height: 17px;
  }
  .dark {
    display: none;
  }
}

button:hover {
  font-family: @font-expanded-regular;
  cursor: pointer;
  background-color: @color-rebel;
  border: solid 1px;
  border-color: @color-black;
  color: @color-black;
  :last-child {
    padding-left: 12px;
  }
  :first-child {
    padding-left: 0px;
  }
  .light {
    display: none;
  }
  .dark {
    padding-bottom: 2px;
    display: block;
    align-self: center;
    height: 17px;
  }
}

.buttonText {
  font-size: small;
}

#logo {
  position: absolute;
  top: 30px;
  left: 30px;
}

article:not(.welcome) {
  padding: 80px 0;
  @media screen and (min-width: @tablet) {
    padding: 50px 0;
  }
}

article.welcome + article {
  min-height: calc(100vh);
}

#oppsett {
  padding: 5% 30%;
  background-color: @color-fire-light;
  color: #1f1f1f;
  @media (max-width: @tablet) {
    padding: 5%;
  }
}

.welcome {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px);
  justify-content: center;
  align-items: left;
  text-align: left;
  padding: 2em;
}

.welcome__header {
  font-size: 3em;
}

.scroll-button {
  background: transparent;
  animation: move 5s infinite ease-out;

  &:focus {
    outline: none;
  }
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  55% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

a {
  color: inherit;
}

.numbered-list {
  margin: auto;
  margin-bottom: 5rem;
  font-family: @font-regular;

  p {
    padding-bottom: 1em;
  }
}

.numbered-list__item {
  display: flex;
  flex-direction: column;
  font-family: @font-regular;
}

.list__item {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

#confetti {
  height: 100%;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: -1;
}

#byttPassord {
  padding: 1em 1em;
  height: inherit;
  width: inherit;
  line-height: 140%;

  .liten-font {
    @media (min-width: @tablet) {
      font-size: 0.8em;
    }
  }

  @media (min-width: @tablet) {
    padding: 2em 4em;
  }
}

#byttPassordVelger {
  text-align: left;
  padding: 25vh 10vw;
}

#byttPassordInnhold {
  padding: 1em;
  @media (min-width: @tablet) {
    padding: 2em;
  }
  display: flex;

  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  @media (max-width: @tablet) {
    flex-direction: column-reverse;
    justify-content: space-evenly;
  }
}

.scrollview {
  height: 90%;
  overflow: scroll;
  @media (min-width: @tablet) {
    align-content: center;
    //overflow: hidden;
  }
}

.morkBakgrunn {
  background-color: @color-black-shadow;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  position: fixed;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalContent {
  font-size: 14px;
  color: @color-black;
  text-align: center;
  height: inherit;
}

.closeBtn {
  font-size: 18px;
  position: absolute;
  right: 25px;
  top: 24px;
  padding: 0.2em 0.3em;
}
